<template>
  <div>
    <video
      v-if="video?.url"
      :src="video.url"
      class="relative z-[2] h-full w-full object-cover transition-opacity duration-500"
      autoplay
      loop
      muted
      playsinline
    />
    <PayloadImage
      :image="props.poster"
      class="absolute inset-0 h-full w-full object-cover"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Image, Video } from '#payload/types'

interface PayloadVideoProps {
  poster: string | Image
  video?: string | Video
}

const props = defineProps<PayloadVideoProps>()
const video = useRelationshipField(toRef(props, 'video'))
</script>
