<template>
  <svg
    class="fill-current"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.8c2.667 0 2.983.008 4.042.058 2.708.125 3.975 1.409 4.1 4.1.05 1.059.058 1.367.058 4.042s-.008 2.983-.058 4.042c-.125 2.691-1.384 3.975-4.1 4.1-1.059.05-1.367.058-4.042.058-2.667 0-2.983-.008-4.042-.058-2.716-.125-3.975-1.417-4.1-4.1C1.808 12.983 1.8 12.675 1.8 10c0-2.667.008-2.983.058-4.042.125-2.691 1.392-3.975 4.1-4.1C7.017 1.808 7.333 1.8 10 1.8ZM10 0C7.283 0 6.942.008 5.875.058 2.242.225.225 2.242.058 5.875.008 6.942 0 7.283 0 10s.008 3.058.058 4.125c.167 3.633 2.184 5.65 5.817 5.817C6.942 19.992 7.283 20 10 20s3.058-.008 4.125-.058c3.625-.167 5.65-2.184 5.817-5.817.05-1.067.058-1.408.058-4.125s-.008-3.058-.058-4.125C19.775 2.25 17.758.225 14.125.058 13.058.008 12.717 0 10 0Zm0 4.867A5.136 5.136 0 0 0 4.867 10c0 2.833 2.3 5.133 5.133 5.133s5.133-2.3 5.133-5.133-2.3-5.133-5.133-5.133Zm0 8.466A3.332 3.332 0 1 1 13.333 10 3.332 3.332 0 0 1 10 13.333Zm5.342-9.875c-.667 0-1.2.534-1.2 1.2a1.2 1.2 0 1 0 1.2-1.2Z"
    />
  </svg>
</template>
