<template>
  <ClientOnly>
    <div class="bg-purple pb-6 pt-7 text-[48px]/none uppercase">
      <MarqueeText :duration="props.items.length * 15" :repeat="10">
        <span
          v-for="({ text }, index) in props.items"
          :key="index"
          v-text="text"
        />
      </MarqueeText>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import MarqueeText from 'vue-marquee-text-component'

interface TickerTextProps {
  items: {
    text: string
  }[]
}

const props = defineProps<TickerTextProps>()
</script>

<style lang="postcss" scoped>
span {
  @apply leading-none;
}

span::after {
  @apply mx-[0.66em] mb-[0.15em] inline-block h-[0.85em] w-[0.5em] bg-contain bg-center bg-no-repeat align-top;

  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23FBFAF3' fill-rule='evenodd' d='M24 7.347A7.317 7.317 0 0 1 22.34 12 7.346 7.346 0 0 1 12 22.336 7.346 7.346 0 0 1 1.66 12 7.346 7.346 0 0 1 12 1.664a7.346 7.346 0 0 1 12 5.683Z' clip-rule='evenodd'/%3E%3C/svg%3E");
}
</style>
