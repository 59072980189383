<template>
  <div v-if="posts">
    <div class="mb-4 flex justify-center md:mb-8 lg:justify-between">
      <h2 class="type-heading-2">
        Sip with us
        <a :href="instagramProfile.url" target="_blank"
          >@{{ instagramProfile.handle }}</a
        >
      </h2>
      <a
        :href="instagramProfile.url"
        target="_blank"
        class="hidden h-10 w-10 items-center justify-center rounded-full bg-black text-white lg:flex"
      >
        <SvgGlyphInstagram />
      </a>
    </div>
    <div class="grid grid-cols-2 gap-5 md:grid-cols-4 xl:grid-cols-6">
      <a
        v-for="{
          id,
          media_type,
          media_url,
          permalink,
          thumbnail_url,
        } in posts.slice(0, 6)"
        :key="id"
        :href="permalink"
        target="_blank"
        class="instagram-post aspect-square overflow-hidden rounded-[10px]"
      >
        <img
          :src="media_type === 'VIDEO' ? thumbnail_url : media_url"
          class="block w-full"
        />
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
const instagramProfile = useInstagramProfile()

const posts = await $fetch('/api/instagram/posts')
  .then((posts) => {
    return posts.filter((post) => !post.is_shared_to_feed)
  })
  .catch(() => {
    // handle silently
  })
</script>

<style lang="postcss" scoped>
.instagram-post:nth-child(5),
.instagram-post:nth-child(6) {
  @apply hidden xl:block;
}

.instagram-post:nth-child(3),
.instagram-post:nth-child(4) {
  @apply hidden md:block;
}

.instagram-post:nth-child(1),
.instagram-post:nth-child(2) {
  @apply block;
}
</style>
