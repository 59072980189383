<template>
  <PageContainer v-if="fields">
    <template #header>
      <div
        ref="headerRef"
        class="relative flex h-[90vh] flex-col text-white md:h-[92vh] lg:h-screen lg:max-h-[940px]"
      >
        <div class="absolute inset-0">
          <PayloadVideo
            v-if="fields.header.background.video"
            :video="fields.header.background.video"
            :poster="fields.header.background.image"
            class="h-full w-full"
          />
        </div>
        <div
          class="gutters relative z-[2] flex flex-grow flex-col items-start justify-end pb-20 text-left"
        >
          <div>
            <h1
              class="type-heading-1 mx-auto mb-4 md:max-w-xl"
              v-text="fields.header.heading"
            />
            <p
              class="type-body-md md:type-body-lg mb-6 md:mb-8 md:max-w-md lg:mx-0 xl:mb-12"
              v-text="fields.header.text"
            />
            <NuxtLink to="/collections" class="btn btn-md md:btn-lg btn-orange"
              >Shop now →</NuxtLink
            >
          </div>
        </div>
      </div>
    </template>
    <template #main>
      <section class="space-y-20 pt-4 md:space-y-28 lg:space-y-16">
        <div class="gutters grid-layout grid-cols-1 md:grid-cols-12">
          <div class="md:col-start-2 md:col-end-12 lg:col-span-6">
            <div
              class="flex flex-col items-start space-y-8 md:items-center lg:items-start"
            >
              <p
                class="md:type-heading-3 text-[28px]/[32px] md:text-center lg:text-left"
                v-text="fields.intro.text"
              />
              <NuxtLink to="/mission" class="btn btn-md btn-border"
                >Our mission →</NuxtLink
              >
            </div>
          </div>
          <div
            class="mb-5 flex justify-end md:col-span-12 lg:order-1 lg:mb-0 lg:items-end lg:justify-start xl:h-16"
          >
            <AnimationFloating class="w-48 xl:w-56" />
          </div>
          <div
            class="px-5 md:col-start-3 md:col-end-11 md:px-0 lg:col-span-6 lg:px-10"
          >
            <PhotoStack
              v-if="fields.intro.images"
              :images="fields.intro.images"
              class="aspect-square lg:aspect-[4/3]"
              :sizes="{
                default: '80vw',
                md: '60vw',
                lg: '45vw',
              }"
            />
          </div>
        </div>
        <TickerText v-if="fields.intro.ticker" :items="fields.intro.ticker" />
      </section>
      <section
        class="mt-16 space-y-12 md:mt-28 md:space-y-16 xl:space-y-28 2xl:space-y-40"
      >
        <div class="gutters">
          <ProductCollection
            v-if="productData"
            :collection="productData.collection"
          >
            <h2 class="type-heading-1">Meet our milks</h2>
          </ProductCollection>
        </div>
        <JunipReviewSlider />
      </section>
      <AsymmetricImageTextSection
        v-if="fields.howItWorks"
        :items="fields.howItWorks.items"
        class="mt-16 md:mt-28"
        heading="How it works"
      />
      <section class="mt-14 md:mt-20">
        <div class="gutters space-y-10 md:space-y-16 xl:space-y-20">
          <div>
            <NuxtLink
              to="/collections/all"
              class="btn btn-border btn-lg md:btn-xl w-full"
              >Shop now →</NuxtLink
            >
          </div>
          <ClientOnly>
            <InstagramFeed />
          </ClientOnly>
        </div>
      </section>
    </template>
  </PageContainer>
</template>

<script lang="ts" setup>
const doc = await usePayloadPage('home')
const fields = doc.value?.homeFields

const { data: productData } = await useCollectionQuery('oat-milks')

const headerRef = ref<HTMLElement | null>(null)
const logoOpacity = ref(1)

onMounted(() => {
  const { top, height } = useElementBounding(headerRef)

  watchEffect(() => {
    logoOpacity.value = Math.min(
      1,
      Math.max(0, 1 + (top.value - 72) / height.value),
    )
  })
})
</script>
